// 设置权限指令
import hasPermi from "./hasPermi";


const install = function(Vue) {
  Vue.directive("hasPermi", hasPermi);
  Vue.directive('float2', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted:function (el) {
      el.querySelector('input').addEventListener('input',function () {
        let val = el.querySelector('input').value;
        if(val){
          val = val.replace(/[^\d\.]/g,'')

          val = val.replace(/^\./g,'')

          val = val.replace(/\.{2,}/g,'.')

          val = val.replace(/\.{2,}/g,'.')

          val = val.replace('.','$#$').replace(/\./g,'').replace('$#$','.')

          val = val.replace('.','$#$').replace(/\./g,'').replace('$#$','.')

          val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
          if(val=='00'){
            val=0
          }
          el.querySelector('input').value = val;
        }
      });
    }
  })
};

if (window.Vue) {
  window["hasPermi"] = hasPermi;
  Vue.use(install); // eslint-disable-line
}

export default install;
