
import common from '@/api/common'
import { getToken, setToken, removeToken } from '@/libs/auth'
import store from "../index";
import router from "../../router";

const user = {
  state: {
    userId: '',
    token: '',
    userName: "",
    nickName: "",
    menus: [],
    roles: [],
    permissions: []
  },

  mutations: {
    QOO_TOKEN: (state, token) => {
      state.token = token;
    },
    QOO_USERID: (state, userId) => {
      state.userId = userId;
    },
    QOO_USERNAME: (state, userName) => {
      state.userName = userName;
    },
    QOO_NICKNAME: (state, nickName) => {
      state.nickName = nickName;
    },
    QOO_ROLES: (state, roles) => {
      state.roles = roles;
    },
    QOO_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    QOO_MENUS: (state, menus) => {
      state.menus = menus;
    }
  },

  actions: {
    SetToken({ commit }, token) {
      commit("QOO_TOKEN", token);
    },
    SetPermissions({ commit }, permissions) {
      commit("QOO_PERMISSIONS", permissions);
    },
    SetRoles({ commit }, roles) {
      commit("QOO_ROLES", roles);
    },
    SetNickName({ commit }, nickName) {
      commit("QOO_NICKNAME", nickName);
    },
    SetUserName({ commit }, userName) {
      commit("QOO_USERNAME", userName);

    },
    SetUserId({ commit }, userId) {
      commit("QOO_USERID", userId);
    },
    SetMenus({ commit }, menus) {
      commit("QOO_MENUS", menus);
    },
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        common.login(userInfo).then(res => {
          commit('QOO_TOKEN', res.token)
          setToken(res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut({ commit }){
      commit("QOO_TOKEN", "");
      commit("QOO_USERID", "");
      commit("QOO_USERNAME", "");
      commit("QOO_NICKNAME", "");
      commit("QOO_ROLES", []);
      commit("QOO_PERMISSIONS", []);
      commit("QOO_MENUS", []);
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        common.getInfo().then(res => {
          commit('QOO_ROLES', res.roles)
          commit('QOO_PERMISSIONS', res.permissions)
          commit('QOO_USERID', res.user.userId)
          commit('QOO_USERNAME', res.user.userName)
          commit('QOO_NICKNAME', res.user.nickName)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 生成路由
    GenerateMenus({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        common.getRouters().then(res => {
          const sdata = JSON.parse(JSON.stringify(res.data))
          const rewriteRoutes = []
          sdata.forEach(item => {
            let ro = {
              path: item.path,
              component: () => import("@/views/Home.vue"),
              meta: item.meta,
              children: []
            }
            if (item.children && item.children.length) {
              item.children.forEach(subItem => {
                ro.children.push({
                  path: item.path,
                  name: subItem.name,
                  meta: subItem.meta,
                  component: () => import("@/views/"+subItem.component+".vue"),
                })
              })
            }
            rewriteRoutes.push(ro);
          })
          rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
          store.dispatch("SetMenus", rewriteRoutes);
          resolve(rewriteRoutes)
        })
      })
    }
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    // if (route.component) {
    //   route.component = loadView(route.component)
    // } else {
    //
    // }
    route.component = () => import("@/views/Home.vue")
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = []
  routes.forEach(route => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route)
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route)
      }
    }
  })
  return res
}

export const loadView = (view) => {
  if (process.env.NODE_ENV === 'development') {
    return (resolve) => require([`@/views/${view}`], resolve)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views/${view}`)
  }
}

export default user;
