import fetch from "@/libs/http";

export default {
  login: (data) => fetch({ url: "/login", token: false, data }),
  capcha: (data) =>
    fetch({ url: "/captchaImage", token: false, data, method: "get" }),
  getInfo: (data) => fetch({ url: "/getInfo", data , method: "get" }),
  getRouters: (data) => fetch({ url: "/getRouters", data , method: "get" }),
  userInfo: (data) => fetch({ url: "/system/user/info", data , method: "get" }),
  getMenus: (data) => fetch({ url: "/system/menu/list", data , method: "get" })
};
