import fetch from "@/libs/http";

export default {
  menus: (data) => fetch({ url: "/system/menu/list", data , method: "get" }),
  treeSelect: (data) => fetch({ url: "/system/menu/treeselect", data , method: "get" }),
  updatePwd: (data) => fetch({ url: "/system/user/profile/updatePwd",contentType: true, data , method: "put" }),
  resetPwd: (data) => fetch({ url: "/system/user/resetPwd", data , method: "put" }),
  delUser: (data) => fetch({ url: "/system/user/{userIds}", data , method: "delete" }),
  getUser: (data) => fetch({ url: "/system/user/{userId}", data , method: "get" }),
  userList: (data) => fetch({ url: "/system/user/list", data , method: "get" }),
  userAdd: (data) => fetch({ url: "/system/user", data , method: "post" }),
  userUpdate: (data) => fetch({ url: "/system/user", data , method: "put" }),
  userChangeStatus: (data) => fetch({ url: "/system/user/changeStatus", data , method: "put" }),
  roleMenuList: (data) => fetch({ url: "/system/menu/roleMenuTreeselect/{roleId}", data , method: "get" }),
  roleList: (data) => fetch({ url: "/system/role/list", data , method: "get" }),
  roleOptionSelect: (data) => fetch({ url: "/system/role/optionselect", data , method: "get" }),
  roleAdd: (data) => fetch({ url: "/system/role", data , method: "post" }),
  roleUpdate: (data) => fetch({ url: "/system/role", data , method: "put" }),
  roleDel: (data) => fetch({ url: "/system/role/{roleId}", data , method: "delete" }),
  roleChangeStatus: (data) => fetch({ url: "/system/role/changeStatus", data , method: "put" }),
  jobList: (data) => fetch({ url: "/job/list", data , method: "get" }),
  jobChangeStatus: (data) => fetch({ url: "/job/changeStatus", data , method: "put" }),
  jobUpdate: (data) => fetch({ url: "/job", data , method: "put" }),
  sysConfigUpdate: (data) => fetch({ url: "/system/config", data , method: "put" }),
  sysConfig: (data) => fetch({ url: "/system/config/list", data , method: "get" }),
  sysConfigInfo: (data) => fetch({ url: "/system/config/configKey/{configKey}", data , method: "get" }),
};
