import fetch from "@/libs/http";

export default {
  list: (data) => fetch({url: "/job/log/list", data, method: "get"}),
  detailList: (data) => fetch({url: "/job/data/log/list", data, method: "get"}),
  execDataSync: (data) => fetch({url: "/job/data/log/execDataSync/{jobSyncDataLogId}", data, method: "put"}),
  jobRun: (data) => fetch({url: "/job/run", data, method: "put"}),
  masterList: (data) => fetch({url: "/job/master/list", data, method: "get"}),
  getSupplierFuncLogList: (data) => fetch({url: "/job/supplier/function/log/list", data, method: "get"}),
  functionList: (data) => fetch({url: "/function/list", data, method: "get"}),
  failureLogList: (data) => fetch({url: "/job/failure/log/list", data, method: "get"}),
}
