<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
  .el-input__inner{border-radius:0;}
  .el-textarea__inner{border-radius:0;}
  .el-switch__core {border-radius:0;border: 0px;height: 40px !important;}
  .is-checked   .el-switch__core { background-color: #66be96 !important; border-color: #66be96 !important; }
  .el-switch__core:after {border-radius:0 !important;}
  .el-switch__core {border-radius: 0 !important;}
  .el-input--suffix .el-input__inner {
      color: rgb(153, 153, 153);
}
/deep/ .el-form-item {
    margin-bottom: 20px;
}
</style>
