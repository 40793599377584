
export function cloneDeep(val){
  if (!val || val instanceof String || val instanceof Number) {
    return val;
  }
  return JSON.parse(JSON.stringify(val));
}

export function ronChangeDate (str) {
  var toDate = {}
  if (!str) {
    toDate.loopType = '単回循環'
  } else {
    var result = str.split(' ').join('')
    var nArr = str.split(' ')
    var countData = this.getPlaceholderCount(result)
    if (!countData.count1) { // 無し'?'週循环
      toDate.loopType = '週循環'
      var keys = nArr[5]
      var en2cnMap = {
        1: '日曜日',
        2: '月曜日',
        3: '火曜日',
        4: '水曜日',
        5: '木曜日',
        6: '金曜日',
        7: '土曜日'
      }
      var cnKeys = keys.split(',').map(function (key, idx) {
        return en2cnMap[key];
      })
      toDate.loopValue = cnKeys.join(',')
    } else if (countData.count1 + countData.count2 === 3) {
      toDate.loopType = '月循環'
      var mot = []
      var mkeys = nArr[3].split(',')
      for (var i = 0; i < mkeys.length; i++) {
        let mo = mkeys[i] + '号'
        mot.push(mo)
      }
      toDate.loopValue = mot.join(',')
    } else {
      toDate.loopType = '天循環'
    }
    toDate.loopTime = nArr[2] + ':' + nArr[1] + ':' + nArr[0]
  }
  return toDate
// console.log(toDate)  {loopType: "月循環", loopValue: "2号,4号,21号", loopTime: "16:30:44"}
}
export function cornList (str) {
    return [
         {label: '１５分繰り返し', value: '0 0,15 * * * ? *'},
         {label: '３０分繰り返し', value: '0 0,30 * * * ? *'},
         {label: '１時間繰り返し', value: '0 0 * * * ? *'},
         {label: '２時間繰り返し', value: '0 0 0/2 * * ? *'},
         {label: '毎日０時', value: '0 0 0 * * ? *'},
         {label: '毎日１時', value: '0 0 1 * * ? *'},
         {label: '毎日２時', value: '0 0 2 * * ? *'},
         {label: '毎日３時', value: '0 0 3 * * ? *'},
         {label: '毎日４時', value: '0 0 4 * * ? *'},
         {label: '毎日５時', value: '0 0 5 * * ? *'},
         {label: '毎日８時', value: '0 0 8 * * ? *'},
         {label: '毎週月曜日', value: '0 0 0 ? 1-12 2'},
    ]
}