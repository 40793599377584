import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import http from "../api/index";
import { getToken } from '@/libs/auth'
Vue.use(Router);

/* Router Modules */
import staticRouter from "./staticRouter.js";

/**
 * constantRoutes
 * 静的ルーティング
 */
export const constantRoutes = [
  ...staticRouter,
  {
    path: "/500",
    name: "Error500",
    component: () => import("@/views/status/Error500.vue"),
  },
  {
    path: "/403",
    name: "Error403",
    component: () => import("@/views/status/Error403.vue"),
  },
  {
    path: "/",
    redirect: {
      name: "login",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },

];

/**
 * asyncRoutes
 * 動的ルーティング, 権限でルーティングの有無を判断する
 */
export const asyncRoutes = [];

/**
 * ルーティングを作成
 */
const createRouter = () =>
  new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes.concat(asyncRoutes),
  });

const router = createRouter();

/**
 * ルーティングをリセット
 */
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

/**
 * 　現在のユーザーをリセットと追加するの権限ルーティング
 */
router.$addRoutes = (params) => {
  resetRouter();
  router.addRoutes(params);
};

router.beforeEach(async (to, from, next) => {
  localStorage.setItem("toPath", to.path)
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/home' })
    } else {
      if (!store.getters.menus || store.getters.menus.length === 0) {
        store.dispatch('GetInfo').then(() => {
          store.dispatch('GenerateMenus').then(routers => {
            router.$addRoutes(routers)
            next({ ...to, replace: true })
          })
        }).catch(err => {
          next({ path: '/login' })
        })
      } else {
        next()
      }
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

/**
 * 　　同じメニューを繰り返しクリックするとエラーが発生する問題を解決する
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
