// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import "./assets/css/resetcommon.css";
import "./libs";
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
import store from "./store";
import permission from "@/libs/directive"; //权限按钮设置
import vInt from '@/libs/int'
var dayjs = require('dayjs')
Vue.prototype.dayjs = dayjs;
Vue.use(vInt);
Vue.use(permission);
import locale from 'element-ui/lib/locale/lang/ja'

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false

console.log("servier api ", process.env.NODE_ENV);
/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount("#app");
