export default [
  {
    path: "/home",
    component: () => import("@/views/Home.vue"),
    meta: { title: "連携システム" }
  },
  {
    path: "/setUser",
    component: () => import("@/views/Home.vue"),
    meta: { title: "ユーザーを設定" },
    children: [
      {
        path: "/setUser",
        name: "setUser",
        meta: { title: "ユーザーを設定" },
        component: () => import("@/views/pages/setUser.vue"),
      }]
  }
];
