import axios from "axios";
import $router from "../router";
import { Loading, Notification, Message } from "element-ui";

let loading = null;
let notify = null;
let requestCount = 0;

/** 創建axios実例*/
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  token: true,
});

/** requestインターセプター，要求パラメータを処理する*/
service.interceptors.request.use(
  (config) => {
    requestCount++;
    loadingShow();

    // set header token
    var token = localStorage.getItem("qoo_token") || "";
    // token このパラメータからtokenを追加する必要があるかどうかを判断する
    if (config.token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    loadingHide();
    // eslint-disable-next-line no-console
    console.log(error);
  }
);

/** responeインターセプター，応答を処理する*/
service.interceptors.response.use((response) => {
    requestCount--;

    if (requestCount <= 0) {
      loadingHide();
    }

    let { code, msg } = response.data;
    if (code != 200) {
      if (code == 401) {
        messageShow("提示", "ID情報が期限切れになりましたので、再登録してください");

        localStorage.removeItem("qoo_token");
        $router.push("/");
      } else {
        messageShow("異常", msg);
      }
    }

    
    return response.data;
  },
  (error) => {
    let res = error.response;
    let title = "異常";
    let msg = "";

    // 返回データがあれば
    if (res) {
      if (![200, 304].includes(res.status)) {
        msg = error.message;
      }
    } else {
      if (error.code === "ECONNABORTED") {
        msg = "タイムアウトを請求するので、ページを更新して再試行してください。";
      } else if (error.code == 500) {
        msg = "システム異常，後でもう一度試してください";
      } else {
        msg = "システム異常，常時エラーコード: " + (res ? res.status : error.message);
      }
    }

    messageShow(title, msg);
    loadingHide();

    // if (res && res.data) {
    //     let code = res.data.code;
    //     // 未ログイン状態からログインになる
    //     if (code === 'E10000') {
    //         Vue.prototype.$utils.delCookie('adminInfo');
    //         $router.push('/');
    //     }
    //     // 権限がない
    //     if (code === 'E10001') {
    //         $router.push('/nopower');
    //     }
    // }
    // Do something with response error
    return Promise.reject(error);
  }
);

// エラーメッセージ表示
function messageShow(title = "異常", msg) {
  if (notify) {
    return;
  }
  notify = Message.error({
    message: msg,
    onClose: function () {
      notify = null
    }
  });

}

function loadingShow() {
  if (loading) {
    return;
  }
  loading = Loading.service({ fullscreen: true });
}

function loadingHide() {
  if (!loading) {
    return;
  }
  requestCount = 0;
  loading.close();
  loading = null;
}

/** 要求を送る*/
const fetch = async (options) => {
  let defConfig = {
    method: "post",
    token: true,
    encode: true,
  };

  let config = Object.assign(defConfig, options);

  // 処理 url '/news/{newsId}'
  if (/\{(\w+)\}/.test(config.url)) {
    var res = config.url.match(/\{(\w+)\}/);

    config.url = config.url.replace(/\{(\w+)\}/, config.data[res[1]]);
    delete config.data[res[1]];
  }
  if (config.encode) {
    if (["get", "put", "delete"].includes(config.method.toLowerCase()) &&config.data) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.data)) {
        const value = config.data[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof value[key] !== "undefined") {
                let params = propName + "[" + key + "]";
                let subPart = encodeURIComponent(params) + "=";
                url += subPart + encodeURIComponent(value[key]) + "&";
              }
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
  } else {
    if (["get","put", "delete"].includes(config.method.toLowerCase())) {
      config.params = config.data;
    }
  }
  return service(config);
};

export default fetch;
