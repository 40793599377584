const getters = {
  user: state => state.user,
  token: state => state.user.token,
  menus: state => state.user.menus,
  userName: state => state.user.userName,
  nickName: state => state.user.nickName,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions
};
export default getters;
