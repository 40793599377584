import Vue from "vue";
// 全局api
import "@/api/index";
import * as utils from "./utils";

// utils
Object.defineProperty(Vue.prototype, "$util", {
  get() {
    return utils;
  },
});
