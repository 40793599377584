import fetch from "@/libs/http";

export default {
  list: (data) => fetch({url: "/supplier/list", data, method: "get"}),
  info: (data) => fetch({url: "/supplier/{supplierId}", data, method: "get"}),
  save: (data) => fetch({url: "/supplier", data, method: "post"}),
  update: (data) => fetch({url: "/supplier", data, method: "put"}),
  functionUpdate: (data) => fetch({url: "/supplier/function/edit", data, method: "put"}),
  changeStatus: (data) => fetch({url: "/supplier/changeStatus", data, method: "put"}),
  del: (data) => fetch({url: "/supplier/{supplierId}", data, method: "delete"}),
  functionEdit: (data) => fetch({url: "/supplier/function/edit", data, method: "put"}),
  functionList: (data) => fetch({url: "/supplier/function/list/{supplierId}", data, method: "get"}),
}
